@import url("https://fonts.googleapis.com/css2?family=Mandali&display=swap");
/* font-family: 'Mandali', sans-serif; */
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700&display=swap");
/* font-family: 'Playfair Display', serif; */

body {
  font-family: "Mandali", sans-serif;
  background: url("./resources/textured-background.png");
  overflow-x: hidden;
  margin: 0;
  padding: 0px;
  box-sizing: border-box;
}

.contenedor {
  overflow-x: hidden;
  width: 100vw;
}

/* HEADER */
header {
  background-color: transparent;
  padding: 1.5rem;
  padding-bottom: 1rem;
  position: relative;
  z-index: +2;
  top: 100px;
}

.logo-video {
  width: 40em;
  height: auto;
  position: absolute;
  top: 35%;
  left: 30%;
}

header ul li {
  list-style: none;
  padding-left: 1.5rem;
  color: white;
}

header ul a {
  text-decoration: none;
  font-family: "Mandali", sans-serif;
  margin: 0 20px;
}

header ul a:hover {
  opacity: 0.6;
  transform: scale(0.98);
}

header ul {
  display: flex;
  float: right;
  padding-right: 1.5rem;
}

.videoBc {
  position: "absolute";
  overflow: "hidden" !important;
  opacity: 60%;
  padding-right: 0px;
}

.videoColor {
  background-color: #32373b;
  margin-top: -81px;
}

.hover {
  position: relative;
  z-index: +2;
  top: -400px;
  left: 650px;
  font-size: 6em;
  color: white;
  margin-bottom: -120px;
}

/* ABOUT US */

.about-container {
  height: 80vh;
  margin-bottom: 100px;
}

.about-us {
  height: 100%;
}

.about-content {
  background: url("./resources/AboutUs.svg");
  background-size: contain;
  background-position: center;
  background-position: center;
  background-repeat: no-repeat;
}

.about-content article {
  background: #fafafa;
  position: relative;
  height: 60%;
  left: 20%;
  top: 500px;
  padding: 25px;
  border-radius: 5px;
  box-shadow: 0px 0px 6px 0px rgb(153, 153, 153);
}

.about-content article h2 {
  margin-bottom: 30px;
  text-align: center;
  font-size: 55px;
  color: #32373b;
}

.about-content article h2 .line {
  width: 50%;
  height: 2px;
  text-align: center;
  margin-left: 25%;
  background-color: #a0814a;
}

.about-content article p {
  margin-bottom: 30px;
  font-size: 18px;
  color: #32373b;
  font-family: "Mandali", sans-serif;
}

.about-img {
  background: url("./resources/about-us.jpg");
  background-position: bottom;
  background-size: cover;
  border-bottom-left-radius: 300px;
}

/* CLIENTS */

.clients-container {
  height: 80vh;
}

.clients {
  height: 100%;
}

.clients-content {
  background: url("./resources/Clients.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.clients-content article {
  background: #fafafa;
  position: relative;
  height: 60%;
  right: 20%;
  bottom: -550px;
  padding: 25px;
  border-radius: 5px;
  box-shadow: 0px 0px 6px 0px rgb(153, 153, 153);
}

.clients-content article h2 {
  margin-bottom: 30px;
  text-align: center;
  font-size: 55px;
  color: #32373b;
}

.clients-content article h2 .line {
  width: 50%;
  height: 2px;
  text-align: center;
  margin-left: 25%;
  background-color: #a0814a;
}

.clients-content article p {
  margin-bottom: 30px;
  font-size: 18px;
  color: #32373b;
  font-family: "Mandali", sans-serif;
}

.clients-img {
  background: url("./resources/leather-clients.jpg");
  background-position: center;
  background-size: cover;
  border-top-right-radius: 500px;
  border-bottom-right-radius: 500px;
}

/* CARDS */

.cards {
  /* background: url(./resources/cards-bg.jpg); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: auto;
  display: flex;
  align-items: center;
  margin: 100px 0;
}

.cards .content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #caa35e4d;
  text-align: center;
  padding: 30px;
  margin: 10px;
  height: 100%;
  width: 70%;
  color: #fff;
  border: 1px solid rgb(199, 199, 199);
  border-radius: 5px;
  box-shadow: 0px 0px 6px 0px rgb(153, 153, 153);
  border-radius: 50%;
  margin-left: auto;
}

.cards .carta .content:hover {
  opacity: 0.8;
  transition: 0.3s ease;
  box-shadow: none;
}

.cards .content img {
  width: 30%;
  margin-bottom: 20px;
}

.cards .content h3 {
  color: #32373b;
  font-size: 1.5rem;
  margin-bottom: 20px;
  font-weight: 600;
}

.cards .content .text {
  font-size: 1.2rem;
  padding: 2px;
}

/* CONTACTS */

.contacts {
  margin: 100px 0;
  width: 100%;
}

.contacts .line-3 {
  width: 50%;
  height: 2px;
  text-align: center;
  margin-left: 25%;
  background-color: #a0814a;
}

.contactosT {
  text-align: center;
  color: #32373b;
  font-size: 3rem;
  font-family: "Mandali", sans-serif;
  font-family: "Mandali", sans-serif;
}

form .message textarea {
  min-height: 200px;
  max-height: 200px;
}

.send-btn {
  color: #fff;
  background: #4a5859;
  font-weight: 500;
}

.send-btn:hover {
  opacity: 0.8;
  color: #fff;
}

/* FOOTER */
footer {
  height: auto;
  background: #a0804a79;
  padding-top: 10vh;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.logoFooter {
  max-height: 4rem;
  margin-top: 1rem;
}

footer h6 {
  font-size: 0.6rem;
}

.footer-info {
  margin-left: 10vw;
  margin-right: 10vw;
}

.container-info {
  margin: 0 10vw;
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-link {
  height: 15vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: rgb(0, 0, 0);
}

.footer-icon {
  color: #514039;
  height: 50px;
  width: 50px;
}

.info-divisor {
  width: 100%;
}

/* TEXTILE ONE */

.textilOne-container {
  height: 80vh;
  margin: 200px 0;
}

.textilOne-us {
  height: 100%;
}

.textilOne-content {
  background: url("./resources/TextileOne.svg");
  background-size: contain;
  background-position: center;
  background-position: center;
  background-repeat: no-repeat;
}

.textilOne-content article {
  background: #fafafa;
  position: relative;
  height: 60%;
  left: 20%;
  top: 81.25rem;
  padding: 25px;
  border-radius: 5px;
  box-shadow: 0px 0px 6px 0px rgb(153, 153, 153);
}

.textilOne-content article h2 {
  margin-bottom: 30px;
  text-align: center;
  font-size: 55px;
  color: #32373b;
}

.textilOne-content article h2 .line {
  width: 50%;
  height: 2px;
  text-align: center;
  margin-left: 25%;
  background-color: #a0814a;
}

.textilOne-content article p {
  margin-bottom: 30px;
  font-size: 18px;
  color: #32373b;
  font-family: "Mandali", sans-serif;
}

.textilOne-img {
  background: url("./resources/leather-chemical.jpg");
  background-position: center;
  background-size: cover;
  /*   border-top-right-radius: 500px;
  border-bottom-right-radius: 500px; */
  border-top-left-radius: 500px;
  border-bottom-left-radius: 500px;
}

/* TEXTILE TWO*/

.textileTwo-container {
  height: 80vh;
  margin: 200px 0;
}

.textileTwo {
  height: 100%;
}

.textileTwo-content {
  background: url("./resources/TextileTwo.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.textileTwo-content article {
  background: #fafafa;
  position: relative;
  height: 60%;
  right: 20%;
  bottom: -1150px;
  padding: 25px;
  border-radius: 5px;
  box-shadow: 0px 0px 6px 0px rgb(153, 153, 153);
}

.textileTwo-content article h2 {
  margin-bottom: 30px;
  text-align: center;
  font-size: 55px;
  color: #32373b;
}

.textileTwo-content article h2 .line {
  width: 50%;
  height: 2px;
  text-align: center;
  margin-left: 25%;
  background-color: #a0814a;
}

.textileTwo-content article p {
  margin-bottom: 30px;
  font-size: 18px;
  color: #32373b;
  font-family: "Mandali", sans-serif;
}

.textileTwo-img {
  background: url("./resources/textileTwo.jpg");
  background-position: center;
  background-size: cover;
  border-top-right-radius: 500px;
  border-bottom-right-radius: 500px;
}

/* Media Querys */

@media screen and (max-width: 90rem) {
  .logo-video {
    top: 20%;
    left: 28%;
  }

  .hover {
    font-size: 4em;
    top: -228px;
    left: 74px;
  }

  header ul {
    font-size: 0.8rem;
  }

  header ul li {
    padding-left: 0;
    text-align: center;
    font-size: 1em;
  }

  .videoColor {
    background-color: #32373b;
    margin-top: -105px;
  }

  /* ABOUT US */
  .about-content article h2 {
    font-size: 40px;
  }

  .about-content article p {
    font-size: 12px;
    margin-bottom: 18px;
  }

  /* CLIENTS */

  .clients-content article h2 {
    font-size: 40px;
  }

  .clients-content article p {
    font-size: 12px;
    margin-bottom: 18px;
  }

  .clients-content article {
    bottom: -480px;
  }

  /* TEXTILE ONE */

  .textilOne-content article h2 {
    font-size: 35px;
  }

  .textilOne-content article p {
    font-size: 14px;
    margin-bottom: 18px;
  }
  .textilOne-content article ul {
    font-size: 14px;
  }

  .textilOne-content article {
    top: 79rem;
  }

  /* TEXTILE TWO */

  .textileTwo-content article h2 {
    font-size: 35px;
  }

  .textileTwo-content article p {
    font-size: 14px;
    margin-bottom: 18px;
  }
  .textileTwo-content article ul {
    font-size: 14px;
  }

  .textileTwo-content article {
    bottom: -850px;
  }

  /* CARDS */

  .cards {
    background: #efe3ce;
    height: 30vh;
    border: 1px solid #cecece;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  }

  .cards .content {
    background: #caa25e00;
    height: 100%;
    width: 70%;
    color: #fff;
    border: 1px solid rgba(199, 199, 199, 0);
    box-shadow: 0px 0px 6px 0px rgba(153, 153, 153, 0);
    margin: auto;
  }
}

@media screen and (max-width: 64rem) {
  .logo-video {
    top: 13%;
    left: 20%;
  }

  .cards .content h3 {
    font-size: 1.2em;
  }
  .cards .content {
    height: 100%;
    width: 100%;
    margin-left: auto;
  }
}

@media screen and (max-width: 48rem) {
  .logo-video {
    width: 21em;
    height: auto;
    position: absolute;
    top: 10%;
    left: 28%;
  }

  .navBar {
    display: none;
  }
  .cards {
    margin: 311px 0 !important;
  }
  .about-content article {
    top: 23px !important;
  }
  .navmenu {
    height: 60px !important;
    width: 60px !important;
  }
}

@media screen and (max-width: 26.563rem) {
  /* HEADER */

  .logo-video {
    width: 14em;
    height: auto;
    position: absolute;
    top: 7%;
    left: 22%;
  }

  video {
    width: 100% !important;
    height: auto !important;
  }

  .videoColor {
    margin-top: 0px;
  }

  header {
    padding: 0px;
    top: 0px;
  }

  .hover {
    display: none;
  }

  .container-logo {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .logo {
    max-width: 15rem;
    height: auto;
    margin-left: 0px;
    position: absolute;
    top: 60px;
    left: 100px;
  }

  .navBar {
    display: none;
  }

  /* ABOUT */

  .about-content {
    height: 0%;
  }

  .about-content article {
    top: 5px;
    width: 95vw;
    left: 0%;
    padding: 15px;
  }

  .about-content article h2 {
    margin-bottom: 10px;
    font-size: 2em;
  }

  .about-content article p {
    text-align: justify;
  }

  .about-img {
    border-top-left-radius: 500px;
    border-bottom-left-radius: 500px;
    margin-left: 25px;
  }

  /* CLIENTS */
  .clients .clients-img {
    height: 100%;
  }

  .clients-container {
    height: 40vh;
  }

  .clients-container {
    height: 40vh;
  }

  .clients-content {
    height: 0%;
  }

  .clients-content article {
    bottom: 500px;
  }

  .clients-content article {
    right: 0%;
    padding: 15px;
    margin-top: 32rem;
  }

  .clients-content article h2 {
    font-size: 1.5em;
  }

  /* CARDS */

  .cards {
    height: auto;
    margin: 35px 0px;
  }

  .cards .content {
    padding: 30px;
    margin: 0px;
    height: 100%;
    width: 100%;
  }

  .carta {
    display: flex;
    align-items: center;
  }

  .cards .content h3 {
    display: none;
  }

  .cards .content img {
    width: 100%;
    margin-bottom: 0px;
  }

  /* TEXTILE LEATHER */

  .textilOne-container {
    height: 40vh;
    margin: 0px;
  }

  .textilOne-content {
    height: 70%;
  }

  .textilOne-img {
    height: 100%;
    margin: 30px 25px;
  }

  .textilOne-content article {
    top: 35rem;
    left: 0%;
    padding: 15px;
    text-align: justify;
  }

  .textilOne-content article h2 {
    font-size: 1.5em;
  }

  /* TEXTILE CHEMICALS */

  .textileTwo-container {
    height: 100vh;
    margin: 0px;
    margin-top: 50vh;
  }

  .textileTwo-img {
    height: 40%;
  }

  .textileTwo-content article {
    bottom: -400px;
    right: 0%;
    padding: 15px;
  }

  .textileTwo-content article h2 {
    font-size: 1.5em;
  }

  .textileTwo-content {
    height: 35%;
  }

  /* CONTACTS */

  .contacts {
    margin-top: -30px;
  }

  /* FOOTER */
  footer {
    padding-top: 5vh;
  }
  .logoFooter {
    max-height: 3.5rem;
    margin-top: 1rem;
  }
}
