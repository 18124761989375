.navmenu {
  display: none;
}

.nav-menu {
  display: none;
}

@media screen and (max-width: 48rem) {
  .navmenu {
    background-color: #060b26;
    height: 80px;
    width: 80px;
    display: block;
    position: fixed;
    bottom: 1vh;
    right: 1vh;
    z-index: 999;
    border-radius: 50%;
  }

  .menu-bars {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 2rem;
    background: none;
  }

  .nav-menu {
    display: block;
    z-index: 999;
    background-color: #060b26;
    width: 250px;
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 1vh;
    right: -100%;
    transition: 0.3s;
    border-radius: 20px;
  }

  .nav-menu.active {
    right: 1%;
    transition: 0.3s;
    padding: 10px 0px;
  }

  .nav-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 0px 8px 16px;
    list-style: none;
    height: 50px;
  }

  .nav-text a {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 14px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0px 16px;
    border-radius: 2px;
  }

  .nav-text a:hover {
    background-color: #091246;
  }

  .nav-menu-items {
    /* width: 100%; */
    padding-left: 0;
  }

  .navbar-toggle {
    background-color: #060b26;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
